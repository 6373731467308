// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-js": () => import("./../src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-other-js": () => import("./../src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-questions-js": () => import("./../src/pages/questions.js" /* webpackChunkName: "component---src-pages-questions-js" */),
  "component---src-pages-sketches-js": () => import("./../src/pages/sketches.js" /* webpackChunkName: "component---src-pages-sketches-js" */),
  "component---src-pages-stories-js": () => import("./../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-theories-js": () => import("./../src/pages/theories.js" /* webpackChunkName: "component---src-pages-theories-js" */),
  "component---src-pages-videos-js": () => import("./../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-words-js": () => import("./../src/pages/words.js" /* webpackChunkName: "component---src-pages-words-js" */)
}

